import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/layout/layout";
import './post.scss';
import SEO from "../components/seo";
import calcReadTime from "../calcReadTime";
import formatDate from "../formatDate";

const Post = ({ data, location }) => {
    const post = data.markdownRemark;
    const sitrUrl = data.site.siteMetadata.siteUrl;

    return (
        <Layout>
            <SEO title={`${post.frontmatter.title} | Максим Дубровкин`} location={location} />
            <div className="content page">
                <div className="post-holder">
                    <div className="post-info">
                    <time>{formatDate(post.frontmatter.date)}</time><span className="read">{post.wordCount.words && calcReadTime(post.wordCount.words)}</span>
                    </div>
                    <div className="post-holder_content"
                        dangerouslySetInnerHTML={{ __html: post.html }}></div>
                    <a className="discuss-post" href={`https://mobile.twitter.com/search?q=${encodeURIComponent(`${sitrUrl}/blog/${post.frontmatter.slug}>`)}`} target="_blank" rel="noopener noreferrer">Discuss on Twitter</a>
                </div>
            </div>
        </Layout>
    );
};
export default Post;
export const query = graphql`
    query($slug: String!) {
        ...SiteInformation
        markdownRemark(fields: {slug: {eq: $slug}}){
            html
            frontmatter {
                id
                slug
                title
                description
                date
            }
            wordCount {
              words
            }
        }
    }
`